.shedule-part{
    margin-top: 100px;
    padding: 100px 0;
}

.shedule-part .speaker{
    display:flex;
    margin-top: 52px;
}

.shedule-part .title{
    text-align: center;
}

.shedule-part .title p{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #fff;
}

.shedule-part .title h1{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 40px;
    color: #fff;
}

.shedule-part .img{
 width: 40%;   
}

.shedule-part .details{
    width: 60%;
    position: relative;
    margin-top: 22px;
}

.shedule-part .date{
    background: #0f2f44;
    width: 156px;
    padding: 5px;
    font-size: 20px;
    border-radius: 5px;
    font-family: 'Barlow Condensed', sans-serif;
    text-align: center;
}

.shedule-part .date p{
    background: linear-gradient(to top,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.shedule-part .name{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 32px;
    color: #fff;
    font-weight: 300;
}

.shedule-part .desination{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 300;
}

.shedule-part .sheduleicon{
    background: #fff;
    padding: 12px 22px;
    border-radius: 5px;
    position: absolute;
    bottom: 6px;
    left: -88px;
}

.shedule-part .sheduleicon span{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #0f2f44;
    font-weight: 500;
    margin-right: 55px;
    position: relative;
}

.shedule-part .sheduleicon span:after{
    content: '';
    background: #f1f1f1;
    width: 50px;
    height: 2px;
    position: absolute;
    top: 12px;
    left: 58px;
}

.shedule-part .sheduleicon i{
    font-size: 20px;
    color: #999999;
    margin-left: 26px;
}

.shedule-part .sheduleicon i:hover{
    color: #0f2f44;
}
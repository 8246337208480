.banner-part{
    text-align: center;
    
}

.banner-part .overlay{
    width: 100%;
    height: 100%;
    background: rgb(110, 6, 174,.6);
   padding-top: 262px;
   padding-bottom: 115px;
}

.banner-part p{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 30px;
    font-weight: 300;
    color: #fff;
    letter-spacing: 15px;
}

.banner-part h1{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 80px;
    font-weight: 800;
    color: #fff;
    width: 580px;
    margin: 0 auto;
    padding-bottom: 103px;
}

.banner-part .form{
    display: none;
}

.banner-part .ericon{
    display: none;
}

.banner-part .counterdown{
    display: flex;
    justify-content: flex-end;
    width: 883px;
}

.banner-part .counterdown .day,
.banner-part .counterdown .hour,
.banner-part .counterdown .min,
.banner-part .counterdown .sec{
    position: relative;
    margin-left: 60px;
}

.banner-part .counterdown .sec{
    margin-left: 40px;
}


.banner-part .counterdown p{
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 800;
    font-size: 80px;
    display: inline-block;
    background: linear-gradient(to top,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-part .counterdown .day p{
    background: linear-gradient(to top,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-part .counterdown .hour p{
    background: linear-gradient(to bottom,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-part .counterdown .min p{
    background: linear-gradient(to top,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-part .counterdown .sec p{
    background: linear-gradient(to bottom,#ffe169, #ffa201);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-part .counterdown .sec p{
    width: 125px;
}

.banner-part .counterdown span{
    color: #ffa201;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 400;
    font-size: 22px;
    position: absolute;
    top: 0;
    right: 0;
}

.banner-part .counterdown .day span{
    bottom: -31px;
    right: -76px;
    transform: rotate(-91deg);
}

.banner-part .counterdown .hour span{
    bottom: -22px;
    right: -75px;
    transform: rotate(-91deg);
}

.banner-part .counterdown .min span{
    bottom: -7px;
    right: -74px;
    transform: rotate(-91deg);
}

.banner-part .counterdown .sec span{
    bottom: -7px;
    right: -64px;
    transform: rotate(-91deg);
}

.banner-part .icon{
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.banner-part .icon i{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: linear-gradient(to right,#ffe169, #ffa201);;
    color: #fff;
    font-size: 30px;
    line-height: 80px;
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
}

.banner-part  .vdo{
    color: #fff;
    font-family: 'Barlow Condensed', sans-serif;
    font-size:20px;
    line-height: 4;
    margin-left: -15px;
}

.banner-part .error{
    display: none;
}

.banner-part .sub{
    display: none;
}
.festive-part{
    margin-top: 100px;
}

.festive-part .slick-dots{
    text-align: center;
}
.festive-part .slick-dots li{
    display: inline-block;
}

.festive-part .slick-dots button{
    font-size: 0;
    width: 12px;
    height: 12px;
    background: transparent;
    border: 1px solid #9e9e9e;
    border-radius: 50%;
    display: inline-block;
    margin-left: 15px;
    outline: 0;;
}

.festive-part .slick-dots li.slick-active button{
    background: linear-gradient(to right,#ffe169, #ffa913);
    border: 1px solid transparent;
    transform: scale(1.1);
}

.festive-part .festive-left .text-img{
    width: 27%;
    margin-top: -24px;
}

.festive-part .festive-left .text{
    width: 73%;
}

.festive-part .festive-left{
    display: flex;
}

.festive-part .festive-left .text-img h1{
    background-image: url('https://i.ibb.co/JzCY78P/festivetextshape.png');
     background-position: center;
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
     font-family: 'Barlow Condensed', sans-serif;
    font-size: 120px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
 }

 .festive-part .festive-left .text-img p{
     text-align: center;
     font-family: 'Barlow Condensed', sans-serif;
     font-size: 33px;
     color: #0f2f44;
     font-weight: 500;
 }

 .festive-part .festive-left .text .text-title{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 32px;
    font-weight: 800;
    width: 274px;
 }

 .festive-part .festive-left .text .para1{
     margin: 30px 0;
 }

 .festive-part .festive-left .text .para1,
 .festive-part .festive-left .text .para2{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #444444;
 }

 .festive-part  .address{
     display: flex;
     border-top: 1px solid #f1f1f1;
     margin-top: 34px;
     padding-top: 40px;
 }
 

 .festive-part  .address .img{
     width: 50%;
 }

 .festive-part  .address .add{
     width: 50%;
 }
 
 .festive-part  .address img.addimg{
     margin-left: 48px;
 }

 .festive-part  .address img{
     float: left;
     margin-right: 10px;
     margin-top: 13px;
 }


 .festive-part  .address p{
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 500;
    font-size: 20px;
    width: 192px;
    color: #222222;
    float: left
 }

 .festive-part  .address p.no{
     width: 144px;
 }

 
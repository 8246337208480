.sponsor-part {
    margin-top: 100px;
}

.sponsor-part .title p {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #0f2f44;
}

.sponsor-part .title h1 {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 40px;
    font-weight: 800;
    color: #0f2f44;
    margin-bottom: 60px;
}

.sponsor-part .img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}



.sponsor-part img {
    width: 200px;
    border: 1px solid #eeeeee;
    object-fit: cover;
    padding: 20px;
    margin: 10px 5px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0);
    transition: .4s;
}

.sponsor-part img:hover {
    background: #f5f5f5;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.about-page nav{
    text-align: center;
}

.about-page .overlay{
    width: 100%;
    height: 100%;
    background: rgb(110, 6, 174,.6);
    padding-top: 175px;
    padding-bottom: 74px;
}

.about-page nav h1{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 32px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.about-page .breadcrumb{
    justify-content: center;
    background: transparent;
}

.about-page .breadcrumb-item+.breadcrumb-item::before{
    content: " " !important;
}

.about-page  ol li a{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
}

.about-page  ol li a i{
    color: #fff;
    font-size: 7px;
    margin-right: 7px;
}

.about-page .breadcrumb-item+.breadcrumb-item{
    padding-left: 0;
}

.about-page  ol li.active a{
    color: #ffe169;
}

.about-page .event {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 90px;    
}


.about-page .event .img{
    width: 16%;
    position: relative;
    margin-top: 10px;
   
}

.about-page .event .img img{
    width: 100%;
}

.about-page .event .overlay{
        width: 100%;
        height: 100%;
        background: linear-gradient(to right,rgba(108, 13, 176,.7),rgb(76, 119, 205,.7));
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: .4s;
}

.about-page .event .img:hover .overlay{
    opacity: 1;
}

.about-page .event .overlay a{
    color: #fff;
    font-family: 'Barlow Condensed', sans-serif;
    font-weight: 100;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: .7;
}
.sponsor-page nav{
    text-align: center;
}

.sponsor-page  .overlay{
    width: 100%;
    height: 100%;
    background: rgb(110, 6, 174,.6);
    padding-top: 235px;
    padding-bottom: 74px;
}

.sponsor-page nav h1{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 32px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
}

.sponsor-page .breadcrumb{
    justify-content: center;
    background: transparent;
}

.sponsor-page .breadcrumb-item+.breadcrumb-item::before{
    content: " " !important;
}

.sponsor-page  ol li a{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    color: #fff;
    font-weight: 400;
    color: #fff;
    text-transform: capitalize;
}

.sponsor-page  ol li a i{
    color: #fff;
    font-size: 7px;
    margin-right: 7px;
}

.sponsor-page .breadcrumb-item+.breadcrumb-item{
    padding-left: 0;
}

.sponsor-page  ol li.active a{
    color: #ffe169;
}

.sponsor-page .sponsor{
    text-align: center;
    
}

.sponsor-page .sponsor h1{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 32px;
    font-weight: bold;
    color: #2e4455;
    text-transform: uppercase;
    margin-top: 100px;
    margin-bottom: 40px;
}

.sponsor-page .sponsor h1 span{
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: lowercase;
    display: block;
}

.sponsor-page .sponsorname{
    position: relative;
    
}

.sponsor-page .sponsorname:after{
    position: absolute;
    top: 103px;
    left: 50%;
    background: #eeeeee;
    width: 1px;
    height: 90%;
    content: "";
    transform: translateX(-50%);
}

.sponsor-page .sponsorname:before{
    position: absolute;
    top: 60%;
    left: 0;
    background: #eeeeee;
    width: 100%;
    height: 1px;
    content: "";
    transform: translateY(-50%);
}
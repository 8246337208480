*{
  margin: 0;
  padding: 0;
}

ul,ol{
  list-style: none;
}

a,a:hover{
  text-decoration: none;
  display: inline-block;
}

h1,h2,h3,h4,h5,h6,p{
  margin: 0;
  padding: 0;
}

body{
  overflow-x: hidden;
}


